<template>
  <div>
    <Loading
      v-if="checkingLinkValid"
      loading-text="Please wait... Validating Link"
      :size="70"
      :width="6"
      :overlay="false"
      class="my-6"
    />
    <div v-else>
      <div v-if="isLinkValid">
        <div class="titleText primary--text py-4">
          <span>Reset Password</span>
        </div>
        <v-form lazy-validation ref="resetForm">
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                label="Password"
                :rules="[rules.required]"
                :type="!showPassword ? 'password' : 'text'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                height="42px"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="confirmPassword"
                label="Confirm Password"
                :rules="[rules.required, matchPassword]"
                :type="!showConfirmPassword ? 'password' : 'text'"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                height="42px"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12" class="text-center py-3 mb-3">
              <v-btn
                class="primary btn-dark px-8 text-capitalize"
                height="48px"
                @click="resetPassword"
              >
                <span>Save Password</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
      <div class="py-6 text-center" v-else>
        <div class="d-flex justify-center">
          <vue-lottie
            :loop="true"
            :animationData="require('@/assets/lotties/error.json')"
            style="height: 140px; width: 140px"
          />
        </div>
        <p class="text-h6 font-weight-bold">Password reset link is invalid.</p>
        <v-btn
          class="text-capitalize px-8 mt-4"
          color="primary"
          height="48px"
          @click="resendLink"
        >
          Resend Link
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
import RULES from "@/common/fieldRules";
import { mapActions } from "vuex";

export default {
  name: "set-password",
  data() {
    return {
      isLinkValid: false,
      checkingLinkValid: false,
      rules: RULES,
      password: "",
      confirmPassword: "",
      showConfirmPassword: false,
      showPassword: false,
    };
  },
  computed: {},
  methods: {
    ...mapActions({
      showSnackbar: "snackBar/showSnackbar",
    }),

    matchPassword(value) {
      if (value === this.password) {
        return true;
      } else {
        return "Password is not match";
      }
    },

    resendLink() {
      this.$router.push({ name: "forgetPassword" });
    },

    checkResetPasswordLink() {
      let formData = {};
      formData["uid"] = this.$route.params.uid;
      formData["token"] = this.$route.params.token;
      const successHandler = () => {
        this.isLinkValid = true;
      };
      const failureHandler = () => {
        this.isLinkValid = false;
      };
      return this.$request(this.$keys.POST, this.$urls.auth.passwordCheckLink, {
        data: formData,
        onSuccess: successHandler,
        onFailure: failureHandler,
        isTokenRequired: false,
      });
    },

    resetPassword() {
      if (this.$refs.resetForm.validate()) {
        this.loading = true;
        const successHandler = (res) => {
          this.showSnackbar({
            text: res.data.message,
            color: "success",
          });
          this.$router.push({ name: "login" });
        };
        const finallyHandler = () => {
          this.loading = false;
        };
        let formData = {};
        formData["password"] = this.password;
        formData["uid"] = this.$route.params.uid;
        formData["token"] = this.$route.params.token;
        return this.$request(
          this.$keys.POST,
          this.$urls.auth.resetPasswordSubmit,
          {
            data: formData,
            onSuccess: successHandler,
            onFinally: finallyHandler,
            isTokenRequired: false,
          }
        );
      }
    },
  },

  async mounted() {
    this.checkingLinkValid = true;
    await this.checkResetPasswordLink();
    this.checkingLinkValid = false;
  },
};
</script>
<style scoped>
</style>
 


